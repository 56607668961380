
<template>
  <div class="container">

    <b-modal
      size="lg"
      ref="edit-form"
      hide-footer
      >

      <div class="d-flex align-items-end flex-column">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
          @click.prevent="create_new_onboarding_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('ONBOARDING.CREATE')}}</a
        >
      </div>

      <b-table
        id="member-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="onboarding_items"
        head-variant="light"
        ref="onboardingTable"
      >
        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">

            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_onboarding_clicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/circle-plus-solid.svg"></inline-svg>
              </span>
            </a>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="delete_onboarding_clicked(row.item.id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>

      </b-table>

    </b-modal>

  </div>

</template>


<script>

/**
 * Get a list of available onboardings from TEST server, or available at local server
 * From here we can choose which onboarding to create on our server, or create a new one.
 */

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {

  name: 'OnboardingListModal',

  props: [],
  emits: ['create', 'update'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {

  },

  computed: {

    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    delete_onboarding_clicked(id) {
      console.log('delete onboarding', id);
    },

    select_onboarding_clicked(id) {
      console.log('selected onboarding', id);
    },

    async create_new_onboarding_clicked() {
      try {
        // Create a new onboarding from scratch.
        // This is usually only done from TEST server.

        await this.get_main_customer();

        if (!this.customer) {
          console.error('no main customer');
          return;
        }

        const res = await axios.post(`/onboarding/${this.customer.id}`, { name: this.customer.name + ' onboarding', description: 'Onboarding for ' + this.customer.name });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('ONBOARDING.CREATED'));
          this.get_onboardings();
        }
      }
      catch (err) {
        console.error('create_new_onboarding_clicked error', err);
      }

    },

    async get_main_customer() {
      try {
        const res = await axios.get(`/customer`);

        if (res.status === 200) {

          const customers = res.data;

          this.customer = customers[0];
        }

      } catch (error) {
        console.error('get_main_customer', error);
      }
    },

    async get_onboardings() {
      try {
        const res = await axios.get(`/onboarding`);

        if (res.status === 200) {
          this.onboarding_items = res.data;
        }

      } catch (error) {
        console.error(error);
      }
    },

    show() {
      this.$refs['edit-form'].show();
    },

    hide() {
      this.$refs['edit-form'].hide();
    },

    async on_submit() {

      if (this.creating) {
        this.$emit('create', this.local_item);
      }
      else {
        this.$emit('update', this.local_item);
      }

      this.hide();
    },
  },

  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }

    this.get_onboardings();

  },

  data() {
    return {
      fields: [

        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return dayjs(item.created_at).format('YYYY-MM-DD');
          },
          sortByFormatted: true,
          tdClass: 'td-short4',
          thClass: 'td-short4'
        },

        {
          key: 'name',
          label: this.$t('COMMON.NAME'),
          sortable: true,
          sortByFormatted: true
        },


        {
          visible: true,
          key: 'actions',
          label: '',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        }
      ],

      onboarding_items: [],

      local_item: {},

    };
  }
};

</script>


<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
