<template>
  <div class="w-100 group" id="onboarding-step-group">

    <div v-if="!create">
      <b-row  class="group-background">
        <b-col cols="10">
          <div class="group">
            <h4 class="text-center mt-2">{{ local_item.name }}</h4>
          </div>
        </b-col>
        <b-col cols="2">
          <div class="justify-content-end d-flex">

            <a
            class="btn btn-icon btn-light btn-sm mx-2"
              @click.prevent="edit_item_clicked"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="delete_group_clicked"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </b-col>
      </b-row>

      <template v-for="s in item.steps">
        <OnboardingStep :item="s" />
      </template>

    </div>


    <b-row v-if="create" class="group-background">
      <b-col cols="10">
        <div class="group">

        </div>
      </b-col>
      <b-col cols="2">
        <div class="justify-content-end d-flex">

          <a
            class="btn btn-icon btn-light btn-sm ml-2"
            @click.prevent="create_group_clicked"
          >
            <i class="fa fa-plus" aria-hidden="true" style="color: #3699fe; font-size: 20px;"></i>

          </a>
        </div>
      </b-col>
    </b-row>


  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import OnboardingStep from '@/view/pages/ml/onboarding/OnboardingStep.vue';

export default {
  name: 'OnboardingStepGroup',
  mixins: [ toasts ],
  props: ['item','create'],
  emits: ['delete', 'create_group_clicked'],
  components: {
    OnboardingStep
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }
  },
  data() {
    return {
      local_item: {},
      file: ''
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {

    },
    currentPeriodId(newValue, oldValue) {

    }
  },
  methods: {

    create_group_clicked() {
      this.$emit('create_group_clicked');
    },

    delete_group_clicked() {
      this.$emit('delete', this.item.id);
    },

    file_updated(file) {
      this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPDATED'));

      this.$refs['file-editor-modal'].hide();
    },

    async select_file(file_id) {

      try {
        const res = await axios.get(`/file/${file_id}?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          this.form = res.data;

          this.$refs['file-editor-modal'].show();
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_DOWNLOAD'));
      }

    },

    handle_file_upload() {
      this.file = this.$refs.file.files[0];

      if (this.file.size > (50000000)) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.MUST_BE_LESS'));
        return;
      }

      this.upload_file();
    },


    select_file_for_upload() {
      this.$refs.file.click();
    },

    async upload_file() {
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('file_id', this.form.file_id);
      formData.append('file_source', 'file');
      formData.append('file_type', 'FILE_OTHER');
      formData.append('form_file_id', this.form.file_id);
      formData.append('company_id', this.currentCompanyId);
      formData.append('period_id', this.currentPeriodId);

      const res = await axios.post('/fileupload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

      if (res.status === 201) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.UPLOADED'));
        return;
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_UPLOAD'));
    },

    async create_file_record() {

      try {
        const res = await axios.post('/file', this.form)

        if (res.status === 201) {

          this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.CREATED'));

        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_CREATE'));
      }

    },
    create_file_clicked() {
      this.select_file_for_upload();
    },

    async download_file_clicked(file_id) {
      try {
        const res = await axios.get(`/file/${file_id}`);

        if (res.status === 200) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FILE.UNABLE_DOWNLOAD'));
      }

    },

  }
};
</script>

<style lang="css" scoped>

.btn {
  border: 1px solid blue !important;
}

.group-background {
  margin-top: 8px;
  padding: 4px;
  background-color: #f9f9f9;
  margin-bottom: 2px;
  border-radius: 7px;
  border: 1px solid #ababab;
}

.group {
  margin-bottom: 2px;
  color: black;
}
</style>

