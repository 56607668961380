<template>
  <div class="w-100 step mt-2" id="page-files">
    
    <b-row>
      <b-col cols="3">
        <b-form-input v-model="local_item.name" />
      </b-col>
      <b-col cols="2">
        <b-form-select 
          :options="department_options"
        />
      </b-col>
      <b-col cols="2">
        <b-form-select 
          :options="status_options"
        />
      </b-col>
      <b-col cols="3">
        <b-form-input v-model="local_item.description" />
      </b-col>

      <b-col cols="2">
        <div class="justify-content-end d-flex mt-1">
          
          <a
            href="#"
            class="btn btn-icon btn-light btn-sm"
            @click.prevent="delete_item_clicked"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>

          <a
          class="btn btn-icon btn-light btn-sm ml-2"
            @click.prevent="download_file_clicked"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
            </span>
            
          </a>

          <a
          class="btn btn-icon btn-light btn-sm ml-2"
            @click.prevent="upload_file_clicked"
          >
            <i class="fa fa-upload" aria-hidden="true" style="color: #3699fe; font-size: 20px;"></i>
            
          </a>

          <a
          class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="edit_item_clicked"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
        </div>
      </b-col>
    </b-row>


    <input hidden type="file" id="file" ref="file" v-on:change="handle_file_upload()" />


  </div>
</template>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'OnboardingStep',
  mixins: [ toasts ],
  props: ['item'],
  components: {
    
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    if (this.item) {
      this.local_item = { ...this.item };
    }
  },
  data() {
    return {
      local_item: {},

      status_options: [
        { value: 'PENDING', text: this.$t('ONBOARDING.STATUSES.PENDING') },
        { value: 'BLOCKED', text: this.$t('ONBOARDING.STATUSES.BLOCKED') },
        { value: 'SKIP', text: this.$t('ONBOARDING.STATUSES.SKIP') },
        { value: 'DELETED', text: this.$t('ONBOARDING.STATUSES.DELETED') },
        { value: 'DONE', text: this.$t('ONBOARDING.STATUSES.DONE') },
      ],

      department_options: [
        { value: 'SALES', text: this.$t('ONBOARDING.DEPARTMENTS.SALES') },
        { value: 'SUPPORT', text: this.$t('ONBOARDING.DEPARTMENTS.SUPPORT') },
        { value: 'TECH', text: this.$t('ONBOARDING.DEPARTMENTS.TECH') },
        { value: 'CUSTOMER', text: this.$t('ONBOARDING.DEPARTMENTS.CUSTOMER') },
      ],
      file: ''
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      
    },
    currentPeriodId(newValue, oldValue) {
      
    }
  },
  methods: {

    download_file_clicked() {},

    upload_file_clicked() {},

    delete_item_clicked() {},

    edit_item_clicked() {},
    
  }
};
</script>

<style lang="css" scoped>
.step {
  margin-bottom: 2px;
  background-color: white;
}

select {
  border: 1px solid #ababab;
}

input {
  border: 1px solid #ababab;
}
</style>

